export const pxToRem = (pxSize, rootSize = 16) => {
  return (pxSize / rootSize).toFixed(2);
};

let useCustomProperties = false;

export const rem = (pxSize) => {
  return `${pxToRem(pxSize)}rem`;
};

export const GRID_GUTTERS = ["16px", "18px", "24px", null, "48px"];


export const spacePx = [
  0,
  8, //
  16,
  24,
  32,
  40,
  48,
  56,
  64,
  72,
  80,
  88,
  96,
  104,
  112,
  120,
  128,
];
export const space = spacePx.map((i) => rem(i));
export const fontSizePx = [16, 18, 24, 26, 48, 56, 76];
export const fontSizes = fontSizePx.map((i) => rem(i));

const theme = {
  useCustomProperties,
  // Fonts
  fonts: {
    body: "'OpenSans', sans-serif",
    heading: "'Tokyo', serif"
  },
  // Colors
  colors: {
    white: "#f9f9f9",
    green: '#435E55',
    lightGreen: '#B9EAC7',
    tomato: '#D64161'
  },
  // Sizing and spacing
  sizes: {
    container: 1500,
  },
  breakpoints: [
    "580px", //0
    "920px", //1
    "1176px", //2
    "1478px", //3
    "1842px", //4
  ],
  space,
  fontSizes,
  fontWeights: {
    body: 500,
    heading: 900,
  },
  //Misc
  styles: {
    root: {
     
      color: "#f9f9f9",
      backgroundColor: "#435E55",
      textRendering: "optimizeLegibility !important",
      "-webkit-font-smoothing": "antialiased !important",
      fontSize: [rem(18), rem(20)],
      'ul li': {
        marginBottom: rem(10)
      },
      'h1, h2, h3': {
        letterSpacing: '0.5px'

      },
      'a': {
        textDecoration: 'underline'
      }
    },
    p: {
      color: "white",
      fontFamily: "body",
      lineHeight: 1.6,
      fontSize: [rem(18), null, null, rem(20)],
    },
  },
};

export default theme;

export const htmlBodyStyles = {
  ...theme.styles.root,
  p: {
    mb: rem(20)
  },
  'ul li': {
    marginBottom: rem(10)
  },
}

// buttons need to match link size
