// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-theme-style-guide-src-template-js": () => import("./../../../node_modules/gatsby-theme-style-guide/src/template.js" /* webpackChunkName: "component---node-modules-gatsby-theme-style-guide-src-template-js" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-comics-mentoring-tsx": () => import("./../../../src/pages/comics-mentoring.tsx" /* webpackChunkName: "component---src-pages-comics-mentoring-tsx" */),
  "component---src-pages-comics-performance-tsx": () => import("./../../../src/pages/comics-performance.tsx" /* webpackChunkName: "component---src-pages-comics-performance-tsx" */),
  "component---src-pages-comics-teaching-tsx": () => import("./../../../src/pages/comics-teaching.tsx" /* webpackChunkName: "component---src-pages-comics-teaching-tsx" */),
  "component---src-pages-comics-tutoring-tsx": () => import("./../../../src/pages/comics-tutoring.tsx" /* webpackChunkName: "component---src-pages-comics-tutoring-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-our-comics-tsx": () => import("./../../../src/pages/our-comics.tsx" /* webpackChunkName: "component---src-pages-our-comics-tsx" */)
}

